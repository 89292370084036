import classNames from 'classnames'
import React from 'react'
import Text from 'components/Text'
import TextWithIcon, { type TextWithIconProps } from 'components/TextWithIcon'
import formatDate from 'libs/format-date'

type Props = {
  children?: React.ReactNode
  data?: number | string | {}
  format?:
    | 'display'
    | 'displayShortWithTime'
    | 'displayTimes'
    | 'displayUTCInLocalWithTime'
    | 'displayWithTime'
    | 'displayWithTimes'
    | 'monthWordAndDay'
    | 'time'
} & Partial<TextWithIconProps>

export default function DateTime({
  children,
  className,
  data = new Date(),
  format = 'display',
  ...props
}: Props) {
  const Component = props.icon ? TextWithIcon : Text

  return (
    <Component
      {...props}
      dateTime={formatDate.timestamp(data)}
      element="time"
      className={classNames('DateTime', className)}>
      {children ?? formatDate[format](data)}
    </Component>
  )
}
